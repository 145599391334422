<template>
    <div>
        <Loader v-if="loading"></Loader>
        <div v-else>
            <CCard v-if="type_level == 'admin'">
                <CCardHeader>
                    <strong>{{ title }}</strong>
                </CCardHeader>
                <CCardBody>
                    <CForm>
                        <div class="quest-inputs">
                            <CInput id="title" description="Título del cuestionario" label="Título del cuestionario" horizontal
                                v-model="questData.title" />

                            <CTextarea id="description" maxlength="1000" description="Descricpción del cuestionario"
                                label="Descripción del cuestionario" horizontal v-model="questData.description" />
                            <CInput id="title" description="Periocidad del cuestionario" label="Periocidad del cuestionario (Días)"
                                horizontal v-model="questData.periodicity" type="number" placeholder="0" />

                            <CRow>
                                <CCol sm="3">
                                    <span class="col-form-label">Necesita dispositivo </span>
                                </CCol>
                                <CCol>
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="questData.need_device">
                                    </label>
                                </CCol>
                            </CRow>
                        </div>
                        <div v-for="(pregunta, index) of auxQuestQuestions" :key="index">
                            <template>
                                <fieldset class="border p-2">
                                    <legend class="w-auto questions">
                                        Pregunta {{ index + 1 }}
                                    </legend>
                                    <CRow>
                                        <CCol sm="10">
                                            <CInput :placeholder="'Las preguntas en blanco no se añadirán al cuestionario'"
                                                class="question" v-model="auxQuestQuestions[index].body" />
                                        </CCol>
                                        <CCol sm="2">
                                            <CButton class="question-btn" color="danger" variant="outline" size="sm"
                                                @click="deleteQuestionInput(index)">
                                                Eliminar pregunta
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <fieldset class="border p-2 ml-4">
                                        <legend class="w-auto answers">
                                            Respuestas
                                        </legend>
                                        <CRow v-for="(respuesta, j) of pregunta.answers" :key="j">
                                            <CCol sm="10">
                                                <CInput v-if="pregunta.type === 'text'"
                                                    :placeholder="'Las respuestas en blanco no se añadirán al cuestionario'"
                                                    class="answer" type="input"
                                                    v-model="auxQuestQuestions[index].answers[j].answer" />
                                                <p v-if="pregunta.type === 'photo' && pregunta.answers.length > 0"> La
                                                    respuesta a esta pregunta será una imagen </p>
                                                <p v-if="pregunta.type === 'EVA' && pregunta.answers.length > 0"> La respuesta
                                                    a esta pregunta será una escala EVA </p>
                                            </CCol>
                                            <CCol sm="2">
                                                <CButton color="danger" variant="outline" size="sm"
                                                    @click="deleteAnswerInput(pregunta.id, j)">
                                                    Eliminar respuesta
                                                </CButton>
                                            </CCol>
                                        </CRow>

                                        <CButton v-if="(pregunta.type === '' || pregunta.type === 'text')" color="info"
                                            square size="sm" @click="addNewAnswerTextInput(pregunta.id)"
                                            style="margin-right: 10px;">
                                            Añadir respuesta de texto
                                        </CButton>
                                        <CButton
                                            v-if="((pregunta.type === '' || pregunta.type === 'photo') && pregunta.answers.length == 0)"
                                            color="info" square size="sm" @click="addNewAnswerImageInput(pregunta.id)"
                                            style="margin-right: 10px;">
                                            Añadir respuesta de imagen
                                        </CButton>
                                        <CButton
                                            v-if="((pregunta.type === '' || pregunta.type === 'EVA') && pregunta.answers.length == 0)"
                                            color="info" square size="sm" @click="addNewAnswerEVAInput(pregunta.id)"
                                            style="margin-right: 10px;">
                                            Añadir respuesta con escala EVA
                                        </CButton>
                                    </fieldset>

                                </fieldset>
                            </template>
                        </div>
                        <div class="d-flex justify-content-center" style="margin-top: 20px">
                            <CButton color="primary" square size="sm" @click="addNewQuestionInput()">
                                Añadir una pregunta
                            </CButton>
                        </div>
                    </CForm>
                    <div class="d-flex justify-content-center" style="margin-top:20px">
                        <CButton class="btn btn-secondary" style="margin-right:1em;" @click="back()">
                            {{ backButton }}
                        </CButton>
                        <CButton class="btn btn-success" @click="editQuest()">
                            {{ textButton }}
                        </CButton>
                    </div>
                </CCardBody>
                <CModal class="c-modal" id="modalCreate" :title="(isEditView) ? 'Editar cuestionario' : 'Crear cuestionario'"
                    color="principal" :show.sync="createModal" :closeOnBackdrop="false">
                    <p class="text-muted">
                        ¿Está seguro de que desea {{ (isEditView) ? 'editar' : 'crear' }} este cuestionario?
                        Todas las preguntas en blanco desapareceran junto a sus respuestas.
                    </p>
                    <div slot="footer">
                        <CButton color="secondary" style="margin-right: 1em;" @click="createModal = false">
                            Cerrar
                        </CButton>
                        <CButton color="success" @click="addQuest()">
                            Aceptar
                        </CButton>
                    </div>
                </CModal>
            </CCard>
        </div>
    </div>
</template>
  
<script>
import { decodePayload } from '@/utils/token';
import { getQuestDataById, createNewQuest, updateQuest } from '@/services/quests';
import { CFormSwitch } from '@coreui/vue'
import Loader from '@/components/Loader.vue';

export default {
    name: "EditarCuestionario",
    components: {CFormSwitch, Loader},
    data() {
        return {
            id: null,
            title: "Nuevo cuestionario",
            backButton: 'Volver',
            textButton: "Crear",
            errorText: 'Campos vacios o erroneos',
            loading: true,
            showQuestions: false,
            questData: {
                title: "",
                description: "",
                periodicity: 0,
                need_device: 0
            },
            auxQuestQuestions: [],
            auxID: -1,
            auxIDAnswer: -1,
            isEditView: false,
            createModal: false,
            newQuest: {},
            contAlertParams: 0,
            type_level: ""
        };
    },
    created() {
        this.type_level = decodePayload(localStorage.token).type
        if (this.type_level != "admin") {
            this.$router.push('/quest/');
            return
        }
        this.id = this.$route.params.id;
        if (this.id != undefined) {
            this.isEditView = true;
            this.textButton = "Editar";
        } else {
            this.isEditView = false;
        }
        this.receiveData();
    },
    methods: {
        receiveData() {
            if (!this.id){
                this.loading = false;
                return;
            } 
            this.title = "Editar cuestionario";
            this.textButton = "Editar";
            this.getQuest()
        },
        showAlertError(msg) {
            this.$store.state.errorAlert = { status: true, msg: msg.toString() }
        },
        /**
         * Función para obtener los datos del cuestionario
         */
        getQuest() {
            getQuestDataById(this.id)
            .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
            .then((result) => {
                this.questData = result
                this.questData.need_device = (this.questData.need_device = 0) ? false : true
                this.auxQuestQuestions = this.questData.questions
                this.loading = false;
            })
        },
        /**
        * Función para añadir nueva respuesta de tipo texto al cuestionario.
        */
        addNewAnswerTextInput(preguntaId) {
            this.auxIDAnswer = this.auxIDAnswer - 1
            let newAnswer = {
                id: this.auxIDAnswer,
                answer: ""
            }
            this.auxQuestQuestions.forEach(element => {
                if (element.id == preguntaId) {
                    element.type = "text"
                    element.answers.push(newAnswer)
                }
            });
        },
        /**
        * Función para añadir nueva respuesta de tipo imagen al cuestionario.
        */
        addNewAnswerImageInput(preguntaId) {
            this.auxIDAnswer = this.auxIDAnswer - 1
            let newAnswer = {
                id: this.auxIDAnswer,
                answer: "",
            }
            this.auxQuestQuestions.forEach(element => {
                if (element.id == preguntaId) {
                    element.type = "photo"
                    element.answers.push(newAnswer)
                }
            });
        },
        /**
        * Función para añadir nueva respuesta de tipo EVA al cuestionario.
        */
        addNewAnswerEVAInput(preguntaId) {
            this.auxIDAnswer = this.auxIDAnswer - 1
            let newAnswer = {
                id: this.auxIDAnswer,
                answer: ""
            }
            this.auxQuestQuestions.forEach(element => {
                if (element.id == preguntaId) {
                    element.type = "EVA"
                    element.answers.push(newAnswer)
                }
            });
        },
        /**
         * Función para poder eliminar la respuesta del cuestionario.
         */
        deleteAnswerInput(preguntaId, position) {
            this.auxQuestQuestions.forEach(question => {
                if (question.id == preguntaId) {
                    question.answers.splice(position, 1)
                }
                if (question.answers.length == 0) {
                    question.type = ""
                }
            });
        },
        /**
         * Función para añadir nueva pregunta  al cuestionario.
         */
        addNewQuestionInput() {
            this.auxID = this.auxID - 1
            let newQuestion = {
                id: this.auxID,
                body: "",
                answers: [],
                type: ""
            }
            this.auxQuestQuestions.push(newQuestion)
        },
        /**
         * Función para eliminar pregunta del cuestionario.
         */
        deleteQuestionInput(position) {
            this.auxQuestQuestions.splice(position, 1)
        },
        /**
         * Función para poder editar el cuestionario.
         */
        editQuest() {
            if (this.questData.title === "" || this.questData.title === null) {
                this.showAlertError("El campo Título esta vacío");
                return;
            }
            if (this.questData.description === "" || this.questData.description === null) {
                this.showAlertError("El campo Descripción esta vacío");
                return;
            }
            this.createModal = true
        },
        /**
         * Función para crear o editar un cuestionario.
         */
        addQuest() {
            let dataToSend = {
                title: this.questData.title,
                description: this.questData.description,
                periodicity: this.questData.periodicity,
                need_device: (this.questData.need_device) ? 1 : 0,
                questions: this.auxQuestQuestions,
            }
            let auxQuestions = JSON.parse(JSON.stringify(dataToSend.questions))
            for (let i = auxQuestions.length - 1; i >= 0; i--) {
                if (auxQuestions[i].body == "") {
                    dataToSend.questions.splice(i, 1)
                }
            }
            this.newQuest = JSON.parse(JSON.stringify(dataToSend))

            if (!this.isEditView) {
                createNewQuest(this.newQuest).then(this.back())
                return
            }

            if (this.isEditView) {
                updateQuest(this.newQuest, this.id).then(this.back())
            }
            this.createModal = false
        },
        /**
        * Función para mostrar el alert durante 10 segundos.
        */
        showFailureParameters() {
            this.contAlertParams = 5;
        },
        /**
         * Función para volver atrás
         */
        back() {
            this.createModal = false
            this.newQuest = {}
            this.$router.push("/quest");
        }
    },
};
</script>
<style>
textarea.form-control {
    height: 200px;
}

input,
textarea {
    color: rgb(49, 49, 49) !important;
}

.quest-inputs {
    margin-bottom: 20px;
}

.c-modal .close {
    visibility: hidden;
}

@media (max-width: 768px) {

    .answers,
    .questions {
        font-size: 15px;
    }

    .question-btn {
        margin: 5px 0;
    }

    .question.form-group {
        margin-bottom: .5rem;
    }
}</style>